import React from 'react'
import SvgIcon from '../components/shared/SvgIcon'
import Link from 'next/link'
import { toggleComingSoon } from '../store/slices/modalSlice'
import { useDispatch } from 'react-redux'

const LandingScreen = () => {
  const dispatch = useDispatch()
  const toggle = () => dispatch(toggleComingSoon())
  const user = JSON.parse(localStorage.getItem('user') || '')

  return (
    <div>
      <div className="h-[91vh] bg-[#F4F9FB] px-3 pt-2 pb-3">
        <div className="grid h-[48%] grid-cols-1 gap-4 md:grid-cols-2">
          <Link href={!user?.registered ? '/payment' : '/patients/register'}>
            <div className="grid w-full cursor-pointer grid-cols-2 place-content-center rounded-md border-2 bg-white p-5 shadow-md">
              <SvgIcon
                src="/images/startConsultation.svg"
                className="ml-0 object-contain md:ml-5"
                alt="startConsultation"
              />
              <div className="mt-4">
                <p className="text-xl font-semibold text-[#003B4D] md:text-3xl">
                  Start
                  <br />
                  Consultation
                </p>
                <SvgIcon
                  src="/images/rightArrowBlue.svg"
                  className="mt-4 object-contain"
                  alt="rightArrowBlue"
                />
              </div>
            </div>
          </Link>

          <Link href={!user?.registered ? '/payment' : '/patients/history'}>
            <div className="grid w-full cursor-pointer grid-cols-2 place-content-center rounded-md border-2 bg-white p-5 shadow-md">
              <SvgIcon
                src="/images/patientHistory.svg"
                className="ml-0 object-contain md:ml-5"
                alt="patientHistory"
              />
              <div className="mt-4">
                <p className="text-xl font-semibold text-[#003B4D] md:text-3xl">
                  Patient
                  <br />
                  History
                </p>
                <SvgIcon
                  src="/images/rightArrowBlue.svg"
                  className="mt-4 object-contain"
                  alt="rightArrowBlue"
                />
              </div>
            </div>
          </Link>
        </div>

        <div className="mt-28 grid h-[48%] grid-cols-1 gap-4 md:mt-4 md:grid-cols-2">
          <Link href="#" onClick={toggle}>
            {/* href="/diagnostics" */}
            <div className="grid w-full basis-2/5 cursor-pointer grid-cols-2 place-content-center rounded-md border-2 bg-white p-5 shadow-md">
              <SvgIcon
                onClick={toggle}
                src="/images/makeDiagnostics.svg"
                className="ml-0 object-contain md:ml-5"
                alt="makeDiagnostics"
              />
              <div className="mt-4">
                <p className="text-xl font-semibold text-[#003B4D] md:text-3xl">
                  Diagnostics
                </p>
                <SvgIcon
                  onClick={toggle}
                  src="/images/rightArrowBlue.svg"
                  className="mt-4 object-contain"
                  alt="rightArrowBlue"
                />
              </div>
            </div>
          </Link>

          {/*  onClick={toggle} */}
          <Link href="/products">
            <div className="grid w-full  basis-2/5 cursor-pointer grid-cols-2 place-content-center rounded-md border-2 bg-white p-5 shadow-md">
              <SvgIcon
                // onClick={toggle}
                src="/images/butProducts.svg"
                className="ml-0 object-contain md:ml-5"
                alt="butProducts"
              />
              <div className="mt-4">
                <p className="text-xl font-semibold text-[#003B4D] md:text-3xl">
                  Sell
                  <br />
                  Products
                </p>
                <SvgIcon
                  // onClick={toggle}
                  src="/images/rightArrowBlue.svg"
                  className="mt-4 cursor-pointer object-contain"
                  alt="rightArrowBlue"
                />
              </div>
            </div>
          </Link>

          {/* <SvgIcon onClick={toggle} src="/images/diwaliSale.svg" className='object-cover basis-1/5 rounded-md' alt='diwaliSale' /> */}
        </div>
      </div>
    </div>
  )
}

export default LandingScreen
